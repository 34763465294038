.SiteLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.TopStocksView {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AssetCardList {
    height: 35em;
    overflow: auto;
}

.ListAndSample {
    max-width: 60%;
}

@media
screen and (max-width: 750px) {
    .ListAndSample {
        max-width: 95%;
    }

    .AssetCardList {
        height: 12em;
    }
}

.SampleTweet {
    overflow: auto;
    margin-bottom: 3em !important;
}