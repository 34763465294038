.SymbolSearchBar {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 4%;
}

.SymbolTextField .MuiOutlinedInput-root{
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.SymbolGoButton {
    border-radius: 0 !important;
    border-right: solid !important;
    border-top: solid !important;
    border-width: thin !important;
    border-bottom: solid !important;
    border-bottom-width: thin !important;
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
}