.SortedAssetsList {
    display: flex;
    flex-direction: column;
    justify-content: center;   
    align-items: center;
}

.TabAndSort {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}

.AssetCardList {
    border-style: solid;
    border-width: thin;
    border-left-width: inherit;
    border-right-width: inherit;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 5px;
}

.SortAndDirectionSelection {
    display: flex;
    height: 26px;
    margin-right: 22px;
}

@media 
screen and (max-width: 750px) {
    .SortAndDirectionSelection {
        max-width: 36%;
    }
}

.AssetTabs {
    margin-left: 22px;
}

.SortAndDirectionSelection .MuiOutlinedInput-root {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.SortDirectionButton {
    border-radius: 0 !important;
    border-right: solid !important;
    border-top: solid !important;
    border-width: thin !important;
    border-bottom: solid !important;
    border-bottom-width: thin !important;
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
}