.fade-enter .container {
    opacity: 0;
    transform: translateY(-100%);
}
.fade-enter-active .container {
    opacity: 1;
    transform: translateY(0%);
}
.fade-exit .container {
    opacity: 1;
    transform: translateX(0%);
}
.fade-exit-active .container {
    opacity: 0;
    transform: translateX(100%);
}
.fade-enter-active .container {
    transition: opacity 500ms, transform 500ms;
}

.fade-exit-active .container {
    transition: opacity 500ms, transform 500ms;
}

.TweetContainer {
      opacity: 0;
}

.TweetContainerV {
      transition: opacity 500ms;
      opacity: 1;
}
  