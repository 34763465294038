.AssetCardContent {
    display: flex;
    flex-direction: column;
    padding: 0px !important;
}

.AssetCard {
    margin-bottom: 4px;
    /* box-shadow: 0px 0px 1px 0px black, 0px 0px 5px 0px black !important; */
    box-shadow: 1px 3px 7px !important;
}

.TopInfoPane {
    display: flex;
    align-items: center;
}

.SymbolBox {
    border-style: solid;
    border-left-width: 0;
    border-top-width: 0;
    border-right-width: thin;
    border-bottom-width: thin;
    padding-left: 4px;
    padding-top: 4px;
    padding-right: 5px;
    margin-right: 5%;
    border-bottom-right-radius: 6px;
    box-shadow: 0px 0px 5px 1px;
}