.DetailSymbol {
    color:blueviolet;
}

.SingleAssetDetail {
    width: 60%;
    height: 50%;
    /* bgcolor: 'primary.light', */
}

.TwitterExample1 {
    border-radius: 16px;
    padding-top: 3px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    max-height: 24em;
    overflow: auto;
    margin-bottom: 3em !important;
}

.TwitterExample2 {
    border-radius: 16px;
    padding-top: 3px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    max-height: 24em;
    overflow: auto;
    margin-bottom: 3em !important;
}

.ChartGridItem {
    min-height: 10em;
    margin-bottom: 1em;
}


@media 
screen and (max-width: 750px) {
    .SingleAssetDetail {
        width: 90%;
    }

    .PositiveExample {
        max-height: unset;
    }

    .NegativeExample {
        max-height: unset;
    }
}
